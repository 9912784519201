body { width: 100%; font-family: 'Microsoft YaHei' }
#root { overflow-x: hidden; }
ul, li, p { list-style: none; padding: 0; margin: 0 }

// app.js

.rootCon { color: #414141; 
  .headNav { position: fixed; top: 2rem; left: 5%; right: 5%; z-index: 9999; background: #fff; color: #000; transition: .5s; box-shadow: 0 1px 3px 1px #e2e2e2;
    .companyLogo { height: 4rem; }
    &.active { top: 0; left: 0; right: 0; }
  }
}



// 导航栏文字蓝色
.headnavbar .navbar-nav .active>.nav-link, .headnavbar .navbar-brand { color: #2CAEFF }
.headnavbar .navbar-nav .nav-link { color: #414141 }
.navbar-toggler { font-size: 1rem; padding: .2rem .5rem }
// #navbarNav { display: flex; justify-content: space-between; }
.banner {  width: 100%; position: relative;
  .banner_conBox { position: absolute; left: 0; top: 0; width: 100%; height: 100%; display: flex; align-items: center; text-align: center; color: #fff; box-sizing: border-box; 
    .banner_con { width: 100%;
      h6.banner_con_title { color: #fff; }
    }
  }
  img { min-height: 400px; width: 100%; object-fit: cover }
}


.bigTitle { margin: 100px 0 30px; text-align: center; }

.rl_system_bg { position: absolute; left: 0; top: 0; width: 100%; height: 100%; z-index: -1; object-fit: cover; }
.rl_system_con {  color: #fff;
  .rl_system_title { text-align: center;  }
  li {margin-bottom: 20px; letter-spacing: 1px; display: flex;
    span { display: inline-block; width: 12px; height: 12px; background: #fff; border-radius: 50%; margin-right: 20px; flex-shrink: 0; margin-top: 7px; display: block; }
    
  }
  img { border-radius: 5px }
}

.cursor-pointer { cursor: pointer; }

.tDely-01 { animation-delay: .1s!important }
.tDely-1 { animation-delay: .5s!important }
.tDely-02 { animation-delay: .2s!important }
.tDely-2 { animation-delay: 1s!important }
.tDely-3 { animation-delay: 1.5s!important }


// Medium devices (tablets, less than 992px)
h5 { color: #000 }
h6 { font-weight: bold; color: #000 }
.fz-12 { font-size: 12px; }
.fz-14 { font-size: 14px; }
.fz-16 { font-size: 16px; }
.fz-18 { font-size: 18px; }
.fz-20 { font-size: 20px; }
.fz-22 { font-size: 22px; }
.fz-24 { font-size: 24px; }
.fz-28 { font-size: 28px; }
.fz-30 { font-size: 30px; }
.fz-36 { font-size: 36px; }
.fz-40 { font-size: 40px; }
.fz-50 { font-size: 50px; }
.fz-70 { font-size: 70px; }
.fz-80 { font-size: 80px; }
.fz-90 { font-size: 90px; }
.banner_home {
  align-items: flex-start !important;
  text-align: left !important;
  .container {
    max-width: 1560px;
  }
  .fz-home-20 {
    font-size: 20px;
    margin-top: 20px;
    letter-spacing: 26px;
  }
  .banner_con {
    &.pt-50 {
      padding-top: 10%;
    }
    .fz-home-50 {
      font-size: 50px;
      font-weight: 500;
      line-height: 68px;
    }
    .home_logo {
      margin-bottom: 50px;
      width: 100px;
      // height: 140px;
      min-height: 3px;
    }
  }
  .home-bottom {
    position: absolute;
    text-align: center;
    bottom: 36px;
    left: 50%;
    width: 100%;
    font-size: 16px;
    letter-spacing: 26px;
    transform: translateX(-50%);
  }
}
@media (max-width: 1439.98px) {
  .banner_home {
    align-items: flex-start !important;
    text-align: left !important;
    .container {
      max-width: 1200px;
    }
    .fz-home-20 {
      font-size: 18px;
      margin-top: 18px;
      letter-spacing: 20px;
    }
    .banner_con {
      &.pt-50 {
        padding-top: 15%;
      }
      .fz-home-50 {
        font-size: 46px !important;
        font-weight: 500;
        line-height: 58px;
      }
      .home_logo {
        margin-bottom: 35px;
        width: 80px;
        // height: 140px;
        min-height: 3px;
      }
    }
    .home-bottom {
      bottom: 32px;
      font-size: 14px;
      letter-spacing: 20px
    }
  }
}
// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  
  .banner_home {
    align-items: flex-start !important;
    text-align: left !important;
    .fz-home-20 {
      font-size: 16px;
      margin-top: 15px;
      letter-spacing: 16px;
    }
    .banner_con {
      &.pt-50 {
        padding-top: 15%;
      }
      .fz-home-50 {
        font-size: 40px !important;
        font-weight: 500;
        line-height: 48px;
      }
      .home_logo {
        margin-bottom: 30px;
        width: 80px;
        // height: 140px;
        min-height: 3px;
      }
    }
    .home-bottom {
      bottom: 30px;
      font-size: 13px;
      letter-spacing: 16px
    }
  }
}

@media (max-width: 991.98px) {
  .fz-14 { font-size: 12px; }
  .fz-16 { font-size: 14px; }
  .fz-18 { font-size: 16px; }
  .fz-20 { font-size: 18px; }
  .fz-22 { font-size: 20px; }
  .fz-24 { font-size: 22px; }
  .fz-28 { font-size: 24px; }
  .fz-30 { font-size: 26px; }
  .fz-36 { font-size: 28px; }
  .fz-40 { font-size: 28px; }
  .fz-50 { font-size: 30px; }
  .fz-70 { font-size: 38px; }
  .fz-80 { font-size: 42px; }
  .fz-90 { font-size: 50px; }
  .banner_home {
    align-items: flex-start !important;
    text-align: left !important;
    .fz-home-20 {
      font-size: 14px;
      margin-top: 13px;
      letter-spacing: 12px;
    }
    .banner_con {
      &.pt-50 {
        padding-top: 140px;
      }
      .fz-home-50 {
        font-size: 30px !important;
        font-weight: 500;
        line-height: 42px;
      }
      .home_logo {
        margin-bottom: 30px;
        width: 60px;
        // height: 140px;
        min-height: 3px;
      }
    }
    .home-bottom {
      bottom: 20px;
      font-size: 10px;
      letter-spacing: 15px
    }
  }
}
@media (max-width: 767.98px) {  
  .banner_home {
    align-items: flex-start !important;
    text-align: left !important;
    .fz-home-20 {
      font-size: 12px;
      margin-top: 10px;
      letter-spacing: 8px;
    }
    .banner_con {
      &.pt-50 {
        padding-top: 120px;
      }
      .fz-home-50 {
        font-size: 28px !important;
        font-weight: 500;
      }
      .home_logo {
        margin-bottom: 5%;
        width: 50px;
        // height: 140px;
        min-height: 3px;
      }
    }
    .home-bottom {
      bottom: 20px;
      font-size: 10px;
      letter-spacing: 8px
    }
  }
  .bigTitle {
    border-left: 5px solid #2CAEFF;; margin-top: 40px; line-height: 1; padding-left: 15px; text-align: left;
  }
}
@media (max-width: 575.98px) {
  .fz-14 { font-size: 10px; }
  .fz-16 { font-size: 12px; }
  .fz-18 { font-size: 14px; }
  .fz-20 { font-size: 16px; }
  .fz-22 { font-size: 18px; }
  .fz-24 { font-size: 20px; }
  .fz-28 { font-size: 22px; }
  .fz-30 { font-size: 22px; }
  .fz-36 { font-size: 22px; }
  .fz-40 { font-size: 30px; }
  .fz-50 { font-size: 28px; }
  .fz-70 { font-size: 32px; }
  .fz-80 { font-size: 36px; }
  .fz-90 { font-size: 40px; }
  .banner_home {
    align-items: flex-start !important;
    text-align: left !important;
    .fz-home-20 {
      font-size: 12px;
      margin-top: 10px;
      letter-spacing: 6px;
    }
    .banner_con {
      &.pt-50 {
        padding-top: 80px;
      }
      .fz-home-50 {
        font-size: 22px !important;
        font-weight: 500;
        line-height: 30px;
      }
      .home_logo {
        margin-bottom: 5%;
        width: 40px;
        // height: 140px;
        min-height: 3px;
      }
    }
    .home-bottom {
      bottom: 10px;
      font-size: 8px;
      letter-spacing: 6px
    }
  }
}











@media (min-width: 576px) { 
  
  
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {  }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {  }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  
}

