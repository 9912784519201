.home_server {
  li {
    padding: 50px 40px 30px; text-align: center; transition: .5s; cursor: pointer; color: #B5B5B5;
    >div {
      box-shadow: 0 0 3px 1px rgb(240, 240, 240); padding: 50px 30px;
    }
    .home_server_img {
      width: 40%; padding:40% 0 0; 
      margin: 0 auto;
    }
    h6 { color: #B5B5B5; padding: 50px 0 20px; }
    &:hover {
      transform: scale(1.2); color: #fff;
      >div {
        background: #2CAEFF; 
      }
      h6 { color: #fff; } 
    }
    &:nth-of-type(1) {
      .home_server_img {
        background: url('../img/home/changjing@2x.png'); background-size: cover;
      }
      &:hover {
        .home_server_img {

          background: url('../img/home/changjing-hover@2x.png'); background-size: cover;
        }
      }
    }
    &:nth-of-type(2) {
      .home_server_img {
        background: url('../img/home/jinrong-default@2x.png'); background-size: cover;
      }
      &:hover {
        .home_server_img {

          background: url('../img/home/jinrong-hover@2x.png'); background-size: cover;
        }
      }
    }
    &:nth-of-type(3) {
      .home_server_img {
        background: url('../img/home/dingzhi@2x.png'); background-size: cover;
      }
      &:hover {
        .home_server_img {

          background: url('../img/home/dingzhi-hover@2x.png'); background-size: cover;
        }
      }
    }
  }
}

.home_step { display: flex; align-items: center;
  >div { width: 100%; text-align: center; position: relative; margin-bottom: 30px;}
  .home_step_icon { cursor: pointer;
    img { width: 100%; background-size: cover; height: 100%; background-size: cover; padding: 20%; }
    &::after {
      position: absolute; content: ''; left: 0; top: 0; width: 100%; height: 100%; background: url('../img/home/1@2x.png'); background-size: cover; transition: .5s
    }
    &:hover {
      &::after {
        transform: rotate(360deg)
      }
    }
  }
  // .home_step_add { font-size: 50px; }
}

.home_good{
  >div div { width: 25%; margin: 0 20px; position: relative; cursor: pointer;
    p { position: absolute; left: 0; top: 0; width: 100%; height: 100%; display: flex; align-items: center;  z-index: 1;
      span { display: block; margin: 0 auto; color: #fff;  }
    }
  }
  img { width: 100%;  }
}
.home_good_list1 {
  
  display: flex; align-items: center;
  margin: 40px 0; position: relative; left: -100px;
}
.home_good_list2 {
  display: flex; flex-direction: row-reverse; align-items: center;position: relative; right: -100px;
}

.comFriBox { min-height: 500px }
.confirm_text { max-width: 600px; margin: 0 auto }
.comFri { display: flex; flex-wrap: wrap; width: 80%; margin: 0 auto; align-items: center;
  >div { width: 20%;  box-sizing: border-box; cursor: pointer; position: relative; overflow: hidden;
    img:nth-of-type(1) { padding: 10px 12px; filter: grayscale(100%); width: 100%; height: 100%;
      // 
    }
    img:nth-of-type(2) {padding: 10px 12px; position: absolute; top: 100%;  z-index: 1; left: 0; width: 100%; transition: .5s; height: 100%; }
    &:hover {
      img:nth-of-type(2) { top: 0 }
    }
  }
  
}


@media (max-width: 992px) { 
  .product .home_step { flex-wrap: wrap;
    >div{
      &{ width: 20%; }
    }
  }
  .home_good >div { position: static; flex-direction: row; 
  }
  .comFri { width: 95%; 
    img { width: 33%; padding: 8px 10px }
  }
}
@media (max-width: 767.98px) {  
  .home .home_step { flex-wrap: wrap;
    >div{
      &{ width: 33%; }
      &:nth-of-type(4){ width: 100%; }
    }
  }
  .home_good >div div { margin: 0 15px }
  
}
