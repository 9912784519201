.ex_pro { margin-bottom: 150px;
  li {
    display: flex; align-items: center;
    margin-top: 78px; cursor: pointer;
    p { color: #999999 }
    img { width: 160px; height: 100px; flex-shrink: 0; padding: 0 30px; display: none }
    .ex_pro_count{ width: 160px; height: 100px; flex-shrink: 0; padding: 0 30px; 
      h6{
        width: 100%; height: 100%; background: red; border-radius: 50%; text-align: center; background: #f1f1f1; margin: 0; line-height: 90px; color: #ccc; font-weight: normal;
      }
    }
    &:nth-of-type(2n - 1) {
      text-align: right
    }
    &:nth-of-type(2n) {
      text-align: left
    }
    &:hover {
      .ex_pro_count { display: none }
      img { display: block; }
    }
  }
}
.souxue_box {
  li {
    margin: 45px 0 !important;
  }
}

.ex_show {
  .navbar-light .navbar-nav .nav-link.active { background: #2CAEFF; border-radius: 4px; color: #fff; padding: 8px 10px; }
  .ex_foot {
    li{
      .ex_footpic {
        position: relative;
        .ex_footpicCon {
          position: absolute; left: 0; bottom: 0; overflow: hidden; transition: .5s; width: 100%; background: rgba(0,0,0,.5); height: 0; display: flex; flex-direction: column-reverse; color: #fff; text-indent: 1em; line-height: 40px;
        }
        &:hover {
          .ex_footpicCon {
            height: 100%;
          }
        }
      }
      
    }
  }
}


@media (min-width: 1200px) { 
  .example {
    .container .banner_con_text { width: 114%; position: relative; left: -9%; } 
  }
 
 
}

@media (max-width: 767.98px) {  
  .example {
    .navbar { background: #fafafa; }
   }
}