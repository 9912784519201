.rtMenu { position: fixed; right: 5%; bottom: 5%; z-index: 888; 
  .rtMenu_list{ width: 48px; height: 48px; cursor: pointer; margin: 10px 0;position: relative; 
    .rtMenu_list_three {  width: 0; height: 0;  border: 10px solid transparent; border-left: 10px solid #fff; transition: .5s ; }
    // &:nth-of-type(1) { background: url('../../img/menu/service-default@2x.png'); background-size: cover; 
    //   &:hover { background: url('../../img/menu/service-hover@2x.png'); background-size: cover;  }
    // }
    .rtMenu_list_con { position: absolute;right: 60px; top: 7px; transition: .5s; display: flex; align-items: center; width: 0; word-break: break-all; overflow: hidden; }
    &:nth-of-type(1) { background: url('../../img/menu/phone-default@2x.png'); background-size: cover;
     
      .rtMenu_list_call { background: #fff; border-radius: 3px; height: 30px; line-height: 30px; padding: 0 10px; position: relative; right: -5px;  }
      &:hover { background: url('../../img/menu/phone-hover@2x.png'); background-size: cover;  
        .rtMenu_list_con { width: 180px; right: 25px; }
      }
      
    }
    &:nth-of-type(2) { background: url('../../img/menu/code-default@2x.png'); background-size: cover;
      .rtMenu_list_con { top: -16px; }
      &:hover { background: url('../../img/menu/code-hover@2x.png'); background-size: cover;
        .rtMenu_list_con { width: 120px; right: 25px; }
      }
      img { width: 80px; height: 80px;  z-index: 1; }
      
    }
    &:nth-of-type(3) { background: url('../../img/menu/back-default@2x.png'); background-size: cover; 
      &:hover { background: url('../../img/menu/back-hover@2x.png'); background-size: cover;  }
    }
  }
}
::-webkit-scrollbar {
  background-color: transparent;
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-track {
  border-radius: 0;
  background: transparent;
}
