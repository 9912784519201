.footer { color: #fff; }
.footer_t { display: flex; align-items: center; height: 50vw; max-height: 260px; background: #151D23; min-height: 180px; overflow: hidden;
  li { cursor: pointer; }
}
.footer_b { height: 35vw; background: #000000; width: 100%; max-height: 180px; min-height: 150px; overflow: hidden;
  img { height: 85%;; padding-left: 5%; padding-right: 10px }
  p { padding: 8px 0; }
}
.footer_b .qrcode {
  height: 55%;
  margin-left: auto;
}