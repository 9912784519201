
.rl_sttext { width: 75% }
.rl_stlist { 
  // li:nth-of-type(2){ .rl_stlist_con_r { max-width: 55%; } }
  // li:nth-of-type(4)  { .rl_stlist_con_r { max-width: 60%; } }
  // li:nth-of-type(3) { .rl_stlist_con_r { max-width: 45%; } }
  .rl_stlist_con {align-items: center; box-shadow: 0 0 10px 1px #ebebeb; cursor: pointer; transition: .5s; padding: 2% 8%;
    // display: flex; align-items: center; 
    // .rl_stlist_con_l { width: 35%; flex-shrink: 0 }
    // .rl_stlist_con_r { flex-grow: 1;  }
    &:hover { transform: scale(1.1) }
    img { width: 12vw; transform: scale(1.2) }
  }
  .rl_stlist_text { text-align: justify; display: flex;
    span { display: inline-block; width: 8px; height: 8px; background: #414141; border-radius: 50%; margin-right: 5px; flex-shrink: 0; margin-top: 7px; }
    p { color: #414141; display: inline-block; }
  }
} 

.rl_stepbox { min-height: 600px }

.product {
  svg {  width: 170px; height: 200px; cursor: pointer;
    &:hover {position: relative; z-index: 99;
      polygon {
        stroke-width: 3px; stroke: #2CAEFF; fill: none; 
      }
      text {  fill: #2CAEFF;  }
    }
    text { text-anchor: middle; font-weight: bold; }
  }
}

.rl_svg {
  display: flex;
  >div { display: flex; margin: 0 auto; }
  .svg_l_t, .svg_r_t {position: relative; left: -42px;
    svg { margin-right: 30px;fill: #EAEAEA; 
    
      &:nth-last-of-type(1) { margin: 0 }
    }
    text { fill: #B5B5B5 }
  }
  .svg_l_b, .svg_r_b { position: relative; right: -42px; top: -80px; z-index: 1;
    svg { margin-left: 30px;fill: #D7D7D7;
      &:nth-of-type(1) { margin: 0 }
    }
    text { fill: #929292 }
  }
}
.svg_l_t, .svg_r_t, .svg_l_b, .svg_r_b {display: flex;
  >div { margin: 0 auto }
}






@media (max-width: 767.98px) {  
  .rl_sttext { width: 100% }
  .resolve {
    .rl_stlist { 
      // .rl_stlist_con { height: 580px; }
      img { width: 50% }
      li { padding: 0 }
    } 
  }

  // .rl_stlist { 
  //   .rl_stlist_con { 
  //     flex-wrap: wrap;
      
  //   }
  //   li { width: 100%; }
  // } 
  
  
  
}
@media (max-width: 992px) {
  .resolve {
    .rl_stlist { 
      li { padding: 1% 2% }
    } 
  }
  .rl_svg { 
    transform: scale(.7); max-height: 500px; 
    // overflow: hidden;
    .svg_l_t, .svg_r_t {
      svg { margin-right: 5px;
      }
    }
    .svg_l_b, .svg_r_b { 
      svg { margin-left: 5px;}
    }
  }
  .myContainer .col-12 {margin: 0; padding: 0 }
  .svg_r { position: relative; top: -150px; }
  // .footer { position: relative; top: -220px; }
}
// [120.204338, 30.171396]