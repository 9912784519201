.pd_software {
  display: flex; justify-content: space-around; flex-wrap: wrap; width: 110%; position: relative; left: -5%; margin-top: 100px; padding-bottom: 80px;
  li { width: 20%; box-sizing: border-box; padding: 35px 35px 60px; color: #595959; cursor: pointer;text-align: center;
    .pd_software_img { width: 100%; padding: 100% 0 0; border-radius: 50%; border: 1px solid #2CAEFF;transition: .3s }
    .pd_software_title {  padding: 15px 0 23px; }
    p { display: none }
    &:hover { color: #fff; background: #2CAEFF; 
      p { display: block }
    }

    &:nth-of-type(1) {
      .pd_software_img { background: url('../img/product/app-default@2x.png') no-repeat; background-size: 56% 56%; background-position: center; }
      &:hover {  
        .pd_software_img { background: url('../img/product/app-hover@2x.png') no-repeat; background-size: 68% 68%; background-position: center }
      }
    }
    &:nth-of-type(2) {
      .pd_software_img { background: url('../img/product/weishangcheng-default@2x.png') no-repeat; background-size: 56% 56%; background-position: center; }
      &:hover {  
        .pd_software_img { background: url('../img/product/weishangcheng-hover@2x.png') no-repeat; background-size: 68% 68%; background-position: center }
      }
    }
    &:nth-of-type(3) {
      .pd_software_img { background: url('../img/product/web-default@2x.png') no-repeat; background-size: 56% 56%; background-position: center; }
      &:hover {  
        .pd_software_img { background: url('../img/product/web-hover@2x.png') no-repeat; background-size: 68% 68%; background-position: center }
      }
    }
    &:nth-of-type(4) {
      .pd_software_img { background: url('../img/product/xiaochengxu-hover@2x.png') no-repeat; background-size: 56% 56%; background-position: center; }
      &:hover {  
        .pd_software_img { background: url('../img/product/xiaochengxu-default@2x.png') no-repeat; background-size: 68% 68%; background-position: center }
      }
    }
    &:nth-of-type(5) {
      .pd_software_img { background: url('../img/product/gongzhonghao-hover@2x.png') no-repeat; background-size: 56% 56%; background-position: center; }
      &:hover {  
        .pd_software_img { background: url('../img/product/gongzhonghao-default@2x.png') no-repeat; background-size: 68% 68%; background-position: center }
      }
    }
  }
}

@media (max-width: 992px) { 
  .pd_software li { width: 33%; padding: 20px; }
}