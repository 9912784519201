
h6.about_title1 { border-left: 5px solid #2CAEFF;; margin-top: 40px; line-height: 1; padding-left: 15px; text-align: left; }


.about_good { text-align: center;
  li { cursor: pointer;
   img {  display: block; margin: 0 auto;  }
  //  &.about_good_centerli p { width: 130%; position: relative; left: -15%; }
  }
  h6 { display: inline-block; border-top: 1px solid #C5C5C5; border-bottom: 1px solid #C5C5C5; padding: 6px 0; margin-top: 20px }
  p { color: #595959;  }
  
}

.about_swiper { position: relative;
  .about_swiper_bg {position: relative; width: 100%; z-index: -1; min-height: 300px; object-fit: cover }
  .about_swiper_conBox {position: absolute; left: 0; top: 0%; height: 100%;;  z-index: 1; width: 100%; overflow: hidden;}
  .about_swiper_con { position: relative; top: 15%; height: 48%;;  z-index: 1; width: 100%; display: flex; }
  ul { display: flex; height: 100%; transform: translateX(0); animation: 30s autoswiper linear infinite;
    // animation: swiperAbout linear 8s infinite;
    li { height: 100%; display: flex; align-items: center; width: 20vw; min-width: 200px; max-width: 280px;
      img { height: 100%; transform: scale(.85); transition: .5s; cursor: pointer; width: 100%; object-fit: cover; border-radius: 4px; }
      img:hover { transform: scale(1);  }
    }
  }
  p {  position: relative; top: 15% }
}
@keyframes autoswiper {
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(-50%)
  }
}

#container { width: 100%; height: 540px;  position: relative; margin-top: 5rem }
.text-myblue { color: #2CAEFF }

.about_route_title { display: flex; justify-content: space-between; align-items: flex-start; position: relative; top: 30px;
  h6 { position: relative; padding: 14px 22px 0;
    &::after { content: ''; position: absolute; top: 0; left: 0; width: 50%; height: 10px; background: #2CAEFF;  }
  }
  div { color: #2CAEFF;  }
}
.about_route_con { padding-bottom: 150px;
  .carousel-inner, #carouselExampleIndicators { width: 100%; }
  .carousel-item {
    position: relative; width: 100%; padding: 0 10%;
    .carousel-item_p { position: absolute; left: 10%; bottom: 0; background: rgba(0,0,0,.6); width: 80%; height: 25%; line-height: 30px;color: #fff; display: flex; align-items: center; box-sizing: border-box; padding: 0 8%;
     p { margin: 0 auto; text-align: center; }
    }
    img { width: 100%; }
  }

  // 前进后退
  .carousel-control-next, .carousel-control-prev { width: 5%; }
  .carousel-control-next-icon { background-color: #EDEDED; background-image: url('../img/about/right.png'); width: 50px; height: 50px; border-radius: 50%; background-size: 65%; background-position: 60% 50%; flex-shrink: 0 }
  .carousel-control-prev-icon { background-color: #EDEDED; background-image: url('../img/about/right.png'); width: 50px; height: 50px;  border-radius: 50%; background-size: 65%; background-position: 60% 50%; transform: rotate(180deg);flex-shrink: 0 }

  // 进度点
  .carousel-indicators {
    top: 100%; display: flex; left: 0%; right: 0%; margin: 0; padding-top: 80px; position: relative;
    &::after { content: ''; border-radius: 20%; position: absolute; left: 0; top: 86px; height: 6px; background: linear-gradient(to right, #f7f7f7, #DDDDDD, #f7f7f7); width: 100%; z-index: -1; }
    li { background: red; border: none; width: 12px; height: 12px; background: #AFAFAF; border-radius: 50%; border: 3px solid #E4E4E4; opacity: 1; flex-shrink: 0;
      &.smallindicators {  border: none; margin-top: 3px;}
      &.li_indicators1 { margin-right: 6% }
      &.li_indicators2 { margin-right: 6% }
      &.li_indicators3 { margin-right: 7% }
      &:nth-last-child(1) {
        margin-right: 0%;
      }
      &.active { background: #2CAEFF; border-color: #95D6FF; 
        p { color: #2CAEFF; }
      }
      p { color: #3B3B3B; text-indent: 0; position: relative; top: 20px; left: -25px; white-space: nowrap; 
      }
    }
  }
}

@media (max-width: 991.98px) {
  .about_route_con { padding-bottom: 0;
    .carousel-item { 
      .carousel-item_p { padding: 0 13%; height: 100%; width: 100%; left: 0; line-height: 1.5;
      }
      
    }
    .carousel-control-next-icon, .carousel-control-prev-icon { width: 30px; height: 30px; }
  }
}
@media (max-width: 575.98px) {
  .about_route_con { 
    .carousel-item { padding: 0 }
    .carousel-control-next, .carousel-control-prev { margin: 2%; width: 8%; }
    
  }
}


.mappop { padding: 20px 10px;
  p { color: #B5B5B5; font-size: 14px; padding: 16px 0 2px; }
  a { font-size: 16px }
  div { font-size: 16px }
  hr { color: #979797; }
}
.about_act_r {
  li { cursor: pointer;
    .about_act_r_date { width: 60px; height: 60px; font-size: 12px; text-align: center;  background: #DFDFDF; flex-shrink: 0; color: #7A7A7A;
      p { font-size: 20px; padding: 6px 0 0; }
    }
    &:hover {
      .about_act_r_date { background: #2CAEFF; color: #fff; }
    }
  }
}

.concat { display: flex;justify-content: space-between;
  li { padding: 5px 10px;
    div { box-shadow: 0 0 1px 2px #f0f0f0; border-radius: 4px;  height: 112px; display: flex; align-items: center;  box-sizing: border-box; padding: 0 22px;  
      p {  margin: 0 auto; }
    }
  }
}

